<template>
  <div>
    <!-- <navbar></navbar> -->

    <h4>TRABAJOS ENCARGADOS A LABORATORIO</h4>
    <div class="row mb-2">
      <div class="col">
        <app-table-registers ref="tr" :getList="getList">
          <template slot="tl">
            <div class="col form-inline">
              <SelectStatus v-model="status"></SelectStatus>
            </div>
            <button
              class="btn btn-secondary mr-1"
              @click="$refs.dSetState.show()"
            >
              ({{ sels }}) Cambiar estado
            </button>
          </template>

          <template slot="table">
            <thead>
              <tr class="text-center">
                <th></th>
                <th>TRATAMIENTO</th>
                <th>CLIENTE</th>
                <th>DOCTOR(A)</th>
                <th>LABORATORIO</th>
                <th>ESTADO</th>
                <th>PAGADO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(l, index) in list.data" :key="index">
                <td class="text-center">
                  <app-checkbox v-model="l.check" placeholder></app-checkbox>
                </td>
                <td class="text-center">
                  <router-link
                    :to="
                      '/dental-clinic/patients/' +
                        l.client_id +
                        '/treatment-plans/' +
                        l.treatment_plan_id
                    "
                    class="text-decoration-none text-dark"
                    >#{{ l.treatment_plan_id }}
                    {{ l.product_name }}
                  </router-link>
                  <!-- #{{ l.treatment_plan_id }}
                  {{ l.product_name }} -->
                </td>
                <td>{{ l.client_name }}</td>
                <td>{{ l.employee_name }}</td>
                <td>
                  <select
                    class="custom-select custom-select-sm"
                    @change="changeSupplier(l, $event.target.value)"
                    v-model="l.supplier_id"
                    v-show="l.status == 1"
                    :disabled="l.status != 1"
                  >
                    <option
                      v-for="(l1, index1) in l.supplier_prices"
                      :key="index1"
                      :value="l1.supplier_id"
                    >
                      <app-span-money
                        :quantity="l1.price"
                        :moneyCode="l1.money_code"
                      ></app-span-money>
                      &nbsp;
                      <span>
                        {{ l1.supplier_name }}
                      </span>
                    </option>
                  </select>
                  <app-span-money
                    :quantity="l.cost"
                    :moneyCode="1"
                  ></app-span-money>
                </td>
                <td>
                  <SelectStatus
                    v-model="l.status"
                    class="custom-select-sm"
                    disabled
                  ></SelectStatus>
                </td>
                <td class="text-center">
                  <app-checkbox
                    :disabled="true"
                    v-model="l.paid_out"
                    placeholder
                  ></app-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
          <template slot="afterpaginate">{{
            list.data ? list.data.total : " "
          }}</template>
        </app-table-registers>
      </div>
    </div>

    <!-- <button class="btn btn-success" @click="$refs.dPayToSel.show()">
      ({{ sels }}) Pagar
    </button> -->

    <app-modal-basic ref="dSetState">
      <h5>CAMBIAR ESTADO</h5>
      <div class="form-group">
        <label for>Estado</label>
        <SelectStatus class="mr-1" v-model="status_to_change"></SelectStatus>
      </div>
      <div class="form-group">
        <label for>Comentario</label>
        <app-textarea v-model="comment"></app-textarea>
      </div>
      <app-button-submit
        text="Cambiar Estado"
        @click="updateStatus()"
      ></app-button-submit>
    </app-modal-basic>

    <app-modal-basic ref="dPayToSel"></app-modal-basic>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import SelectStatus from "../../logistic-module/supplier-jobs/SelectStatus";
// import navbar from "../laboratories/navbar";

export default {
  components: {
    SelectStatus,
    // navbar
  },
  data: () => ({
    list: {},
    status: 1,
    comment: "",
    status_to_change: undefined
  }),
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.status = to.query.status;
      }
    },
    status() {
      this.$refs.tr.gl();
    }
  },
  computed: {
    sels() {
      return this.list.data ? this.list.data.filter(x => x.check).length : 0;
    }
  },
  methods: {
    changeSupplier(l, supplier_id) {
      DentalClinicService.putLaboratoryJob({
        id: l.id,
        change_supplier: 1,
        supplier_id: supplier_id,
        comment: this.comment
      }).then(() => {
        this.$refs.tr.gl();
      });
    },
    updateStatus() {
      DentalClinicService.putLaboratoryJobStatus({
        list: this.list.data.filter(x => x.check).map(x => x.id),
        status: this.status_to_change
      }).then(() => {
        this.$refs.tr.gl();
        this.$refs.dSetState.hide();
      });
    },
    getList({ page, search }) {
      return new Promise(rsv => {
        DentalClinicService.getLaboratoryJobs({
          page,
          search,
          status: this.status
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style>
</style>
