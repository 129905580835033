<template>
  <select class="custom-select" v-model="valueChild">
    <option v-for="(l, index) in supplierState" :value="index" :key="index">{{l}}</option>
  </select>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      supplierState: s => s.config.logistic.supplier_jobs.states
    }),
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  props: {
    value: {}
  }
};
</script>

<style>
</style>
